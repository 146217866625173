import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import React from 'react'
import get from 'lodash/get'
import blogPost from './blog-post.module.css'
import Button from '../components/button'

class Contact extends React.Component {
  render() {
    const exhibitions = get(this, 'props.data.allContentfulExhibition.edges')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location} exhibitions={exhibitions}>
        <Helmet title={`Contact | ${siteTitle}`} />
        <div className={blogPost.wrapper}>
          <div className={blogPost.leftColumn}>
            <h1 className={blogPost.title}>Contact</h1>
            <p>
              If you'd like to get in touch about my work or have any questions
              about anything you see on this site, please don't hesitate to get
              in touch. Use the form to the right or use the following contact
              details.
            </p>
            <p>
              <label>
                Email: <span>tommyclancypics@gmail.com</span>
              </label>
              <label>
                Phone: <span>+353 (87) 230-9947</span>
              </label>
            </p>
          </div>
          <div className={blogPost.rightColumn}>
            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contact"
              action="/contact-success"
            >
              <input type="hidden" name="form-name" value="contact"></input>
              <p>
                <label>
                  Your Name (required): <input required type="text" name="name" />
                </label>
              </p>
              <p>
                <label>
                  Your Email (required): <input required type="email" name="email" />
                </label>
              </p>
              <p>
                <label>
                  Message (required): <textarea required name="message"></textarea>
                </label>
              </p>
              <p>
                <Button type="submit">Submit</Button>
              </p>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query ContactPage {
    site {
      siteMetadata {
        title
      }
    }

    allContentfulExhibition {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
